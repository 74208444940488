




































import { ModelInformation } from "@/interfaces/cars";
import { Component, Prop } from "vue-property-decorator";
import wiki from "wikijs";
import reader from "@/mixins/reader";
import {mixins} from "vue-class-component";

@Component
export default class CarCard extends mixins(reader) {
  @Prop() private carModel!: ModelInformation;
  private summary = "";
  private link = "";
  private pictureUrl = "car.png";

  private currentlySpeaking = false;
  private icon = "mdi-volume-high";
  // eslint-disable-next-line no-undef
  private currentSynth?: SpeechSynthesis;

  mounted(): void {
    wiki({ apiUrl: "https://en.wikipedia.org/w/api.php" })
      .find(this.carModel.Make_Name + " " + this.carModel.Model_Name)
      .then((page) =>
        page.summary().then((value) => {
          if (value.length > 300) {
            this.summary = value.substr(0, 300) + "...";
          } else {
            this.summary = value;
          }
          this.link = page.url();
        })
      );
  }

  beforeDestroy(): void {
    this.currentSynth?.cancel();
    console.log("leaving car card!");
  }

  // vorlesen starten
  clickRead(): void {
    this.currentlySpeaking = !this.currentlySpeaking;
    if (this.currentlySpeaking) {
      this.icon = "mdi-stop";
      try {
        this.currentSynth = this.speakString(this.summary, this.setStop);
      } catch (e) {
        console.log("Error reading! Try again later!")
      }
    } else {
      this.setStop();
    }
  }

  // vorlesen wurde beendet, entweder durch benutzer oder weil fertig gelesen
  setStop(): void {
    this.currentSynth?.cancel();
    this.icon = "mdi-volume-high";
  }
}
