



























































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import axios from "axios";
import Datasource from "@/components/Datasource.vue";
import SpotifySong from "@/components/SpotifySong.vue";
import { Song } from "@/interfaces/spotify";

@Component({
  components: {
    Datasource,
    SpotifySong
  },
})
export default class Lyrics extends Vue {
  // spotify search
  searchQuery = "";
  spotifySongs = {} as Song[];

  title = "" as string;
  htmlText = "" as string;
  loading = false;
  showError = false;

  // sparkline settings
  gradient = [
    "#1db954", "#179443"];
  value = [0, 2, 5, 9, 5, 10, 3, 5, 7, 4, 6, 8, 2, 9, 0, 4];

  async searchLyrics(artist: string, song: string): Promise<void> {
    this.loading = true;
    console.log("ahh suche jezt zeug");
    try {
      let response = await axios.get("https://api.lyrics.ovh/v1/" + artist + "/" + song);
      let cleaned = this.cleanLyrics(response.data.lyrics);
      this.title = cleaned[1];
      this.htmlText = cleaned[0];
      this.loading = false;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        this.showError = true;
        this.loading = false;
      }
    }
  }

  cleanLyrics(lyrics: string): [string, string] {
    let titleLine = (lyrics).split("\r\n")[0];
    let artistName = titleLine.split("par")[1];
    let title = titleLine.split("par")[0].substring(22);

    let htmlLyrics = lyrics
        .split("\r\n")[1]
        .replaceAll("\n", "<br>");
    return [htmlLyrics, title + " von " + artistName];
  }

  async searchSpotify(): Promise<void> {
    if (!this.searchQuery) {
      this.spotifySongs = {} as Song[];
      return;
    }
    this.loading = true;

    // Build backend URL
    var url;
    if (process.env?.VUE_APP_BACKEND_URL) {
      url = process.env?.VUE_APP_BACKEND_URL + "/songsearch?search=" + this.searchQuery;
    } else {
      url = "/songsearch?search=" + this.searchQuery;
    }

    let response = await axios.get<Song[]>(url);
    this.spotifySongs = response.data;
    this.loading = false;
  }
}
