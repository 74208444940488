



























import { TriviaQuestion } from "@/interfaces/trivia";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class QuestionHintDialog extends Vue {
  @Prop() private question!: TriviaQuestion;
  // ist der Dialog offen?
  private dialog = false;

  // methode die den Dialog öffnet
  openDialog(): void {
      this.dialog = true;
  }

  copy(): void {
    // Antwort in Zwischenablage kopieren
    navigator.clipboard.writeText(this.question.answer);
  }
}
