























import { Song } from "@/interfaces/spotify";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class SpotifySong extends Vue {
  // der spotify song
  @Prop() private song!: Song;
  // methode die ausgeführt wird wenn lyrics anzeign geklickt wird
  @Prop() private lyricsCallback!: (artist: string, song: string) => void;
}
