






































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import {
  Manufacturer,
  ManufacturerQueryResult,
  ModelQueryResult,
} from "@/interfaces/cars";
import axios, { AxiosError } from "axios";
import Datasource from "@/components/Datasource.vue";
import CarCard from "@/components/CarCard.vue"

@Component({
  components: {
    Datasource,
    CarCard
  },
})
export default class Cars extends Vue {
  manufacturers = [] as Manufacturer[];

  mounted(): void {
    // beim laden Hersteller fetchen
    this.loadManufacturers();
  }

  loadManufacturers(): void {
    axios
      .get<ManufacturerQueryResult>(
        `https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json`
      )
      .then((response) => {
        this.manufacturers = response.data.Results.slice(0, 200).sort((a, b) =>
          a.Make_Name.localeCompare(b.Make_Name)
        );
        console.log(this.manufacturers);
      })
      .catch((err: AxiosError): void => {
        console.error(`Error loading data from NHTSA: ${err.message}`);
      });
  }

  loadModelsForOneManufacturer(makeID: number): void {
    console.log("Loading Models... " + makeID);

    let updateItem = this.manufacturers.find((item) => item.Make_ID == makeID);

    // theoretically this could never happen since we only get the makeID from the existing list
    // but you never know
    if (updateItem == undefined) {
      console.log("Something weird happend during loading of the vehicles for manufactuerer " + makeID);
      return;
    }

    let index = this.manufacturers.indexOf(updateItem);
    axios
      .get<ModelQueryResult>(
        `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMakeId/${makeID}?format=json`
      )
      .then((response) => {
        // workaround
        Vue.set(this.manufacturers[index], "Models", response.data.Results);
      })
      .catch((err: AxiosError): void => {
        console.error(
          `Error loading make data for makeID ${makeID} from NHTSA: ${err.message}`
        );
      });
  }
}
