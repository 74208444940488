





















































































import { Component, Vue } from "vue-property-decorator";
import "vuetify/lib";

@Component
export default class Home extends Vue {}
