import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Trivia from "@/views/Trivia.vue";
import Lyrics from "@/views/Lyrics.vue"
import Cars from "@/views/Cars.vue"

Vue.use(VueRouter)

// hier steht jede Route drin
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/trivia',
    name: 'Trivia Questions',
    component: Trivia
  },
  {
    path: '/lyrics',
    name: 'Lyrics Search',
    component: Lyrics
  },
  {
    path: '/cars',
    name: 'Cars',
    component: Cars
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
