





























import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class BackendCheck extends Vue {
  loading = true;
  backendAvailable = false;

  mounted(): void {
    console.log(
      "Trying to reach backend with URL: " + process.env?.VUE_APP_BACKEND_URL
    );
    
    // Build backend URL
    var url;
    if (process.env?.VUE_APP_BACKEND_URL) {
      url = process.env?.VUE_APP_BACKEND_URL + "/ping";
    } else {
      url = "/ping";
    }

    axios.get(url)
    .then(this.setAvailable)
    .catch(this.setUnavailable);
  }

  setAvailable(): void {
      this.loading = false;
      this.backendAvailable = true;
  }

  setUnavailable(): void {
      this.loading = false;
      this.backendAvailable = false;
  }
}
