import Vue from 'vue';
import Component from "vue-class-component";

@Component
export default class Reader extends Vue {
    /**
     * Liest einen Text vor
     * @param text der Text der vorgelesen werden soll
     * @param onEnd methode die ausgeführt wird nach Ende des Vorlesens
     * @returns das SpeechSynthesis Objekt
     */
    speakString(text: string, onEnd: () => void): SpeechSynthesis {
        const synth = window.speechSynthesis;
        const utterThis = new SpeechSynthesisUtterance(text);
        utterThis.pitch = 1
        utterThis.rate = 1;
        const voices = synth.getVoices();
        if (voices.length < 2) {
            throw synth;
        }
        utterThis.voice = voices.filter(voice => voice.lang == "en-US")[0];
        synth.speak(utterThis);
        utterThis.onend = onEnd;
        return synth;
    }
}