import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/src/locale/de';
import {VIcon, VTextField, VSnackbar, VParallax} from "vuetify/lib/components";

// hier wird definiert welche Komponenten aus Vuetify ich benutze
Vue.use(Vuetify, {components: {
  VTextField, VIcon, VSnackbar, VParallax
  }});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3F77BF',
        secondary: '#424242',
        accent: '#d64949',
        error: '#FF5252',
        info: '#A9C2D9',
        success: '#4CAF50',
        warning: '#BF8845',
        
      },
    },
  },
    lang: {
      locales: { de },
      current: 'de',
    }
});
