


































import {Ref, Vue, Watch} from "vue-property-decorator";
import Component from "vue-class-component";
import { TriviaQuestion } from "@/interfaces/trivia";
import axios from "axios";
import Datasource from "@/components/Datasource.vue";
import QuestionCard from "@/components/QuestionCard.vue";
import QuestionHintDialog from "@/components/QuestionHintDialog.vue";

const clueCheckointEvery = 2000;

@Component({
  components: {
    QuestionCard,
    Datasource,
    QuestionHintDialog,
  },
})
export default class Trivia extends Vue {
  wiggleClass = "";
  currentQuestion = {} as TriviaQuestion;
  punktestand = 0;
  remainingClues = 5;
  antwortEingabe = "";
  @Ref("hintDialog") readonly hintDialog!: QuestionHintDialog

  // get new clue every 2000 points
  @Watch("punktestand")
  getClue(value: number, oldValue: number):void {
    if (value % clueCheckointEvery < oldValue % clueCheckointEvery) {
      this.remainingClues++;
    }
  }

  mounted(): void {
    this.loadQuestion();
  }

  loadQuestion(): void {
    axios
      .get<TriviaQuestion[]>("https://jservice.io/api/random")
      .then((response) => {
        this.currentQuestion = response.data[0];
        if (this.currentQuestion.value <= 0 || !this.currentQuestion.value){
          this.loadQuestion();
        }
      });
  }

  verify(): void {
    if (this.compareStrings()) {
      this.punktestand += this.currentQuestion.value;
      this.antwortEingabe = "";
      this.loadQuestion();
    } else {
      console.log("Falsche Antwort!");
      this.wiggleClass = "wrong-answer"
      setTimeout(() => this.wiggleClass = "", 1000);
    }
  }

  useClue(): void {
    this.remainingClues--;
    this.hintDialog.openDialog()
  }

  compareStrings(): boolean {
    let cleanedInput = this.antwortEingabe.replace(/[^a-zA-Z0-9]/g, "");
    let cleanedAnswer = this.currentQuestion.answer.replace(
      /[^a-zA-Z0-9]/g,
      ""
    );
    console.log("Input: " + cleanedInput);
    console.log("Answer: " + cleanedAnswer);
    return cleanedInput.toLowerCase() === cleanedAnswer.toLowerCase();
  }
}
