























import { TriviaQuestion } from "@/interfaces/trivia";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class QuestionCard extends Vue {
  // die Frage kommt als HTML propery
  @Prop() private question!: TriviaQuestion;
}
