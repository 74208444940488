













































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";
import BackendCheck from "./components/BackendCheck.vue";

@Component({
  components: {
    BackendCheck
  }})
export default class App extends Vue {
  // dynamische Menü Items
  navItems = [
    { title: "Home", icon: "mdi-home", link: "/" },
    { title: "Lyrics", icon: "mdi-file-music", link: "/lyrics" },
    { title: "Trivia", icon: "mdi-chat-question", link: "/trivia" },
    { title: "Cars", icon: "mdi-car", link: "/cars" },
  ];
  // gibt an ob mobiler Drawer geöffnet
  drawer = false;

  mobile(): boolean {
    return (
      this.$vuetify.breakpoint.name == "xs" ||
      this.$vuetify.breakpoint.name == "sm"
    );
  }
}
